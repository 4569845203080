import React, { useState, useEffect } from 'react';

import { css } from '@emotion/core';

import LogoLasNipas from '../../../../images/logo/logo-las-nipas-01.png';
import home01 from '../../../../images/background/home01.jpg';
import home02 from '../../../../images/background/home02.jpg';
import home03 from '../../../../images/background/home03.jpg';
import home04 from '../../../../images/background/home04.jpg';
import { motion } from 'framer-motion';

const variants = {
  enter: {
    // backdropFilter: `blur(10px)`,
    opacity: 1,
    zIndex: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    // backdropFilter: `blur(0px)`,
    opacity: 0,
    zIndex: 0,
    transition: {
      duration: 1,
    },
  },
};

const ImageSlide = ({ src, open = false, ...props }) => {
  return (
    <motion.div
      {...props}
      initial="exit"
      animate={open ? 'enter' : 'exit'}
      variants={variants}
      transition={{ ease: 'easeOut', duration: 2 }}
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      `}
    >
      <motion.img
        src={src}
        css={css`
          width: 100%;
          height: 100%;
          object-position: 0px -200px;
          object-fit: cover;
          @media only screen and (max-width: 600px) {
            object-fit: cover;
          }
        `}
        animate={{
          scale: [1, 1.1, 1],
          objectPosition: ['0px -50px', '0px 0px', '0px -50px']
        }}
        transition={{
          duration: 10,
          ease: "easeOut",
          // times: [0, 1, 1],
          loop: Infinity,
          // repeatDelay: 1,
        }}
      />
    </motion.div>
  );
};

const ImageViewer = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [home01, home02, home03, home04];

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % images.length);
    }, 5000);
    return () => clearTimeout(timer);
  }, [currentIndex, setCurrentIndex]);

  return (
    <div
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        width: 100vw;
        height: 90vh;
        object-fit: cover;
        filter: brightness(0.75);
        clip-path: polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0 90%);
        overflow: hidden;
      `}
    >
      {images.map((x, i) => (
        <ImageSlide src={x} key={x} open={currentIndex === i} />
      ))}
    </div>
  );
};

export const HomeScreen = () => {
  return (
    <>
      <ImageViewer />
      <div
        css={css`
          color: white;
          display: grid;
          width: 100vw;
          height: 100vh;
          justify-content: center;
          padding-top: 15vh;
        `}
      >
        <img
          src={LogoLasNipas}
          css={css`
            width: 50vw;
            height: 50vh;
            object-fit: scale-down;
            z-index: 10;
          `}
        />
      </div>
    </>
  );
};

export default HomeScreen;
