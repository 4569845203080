import React from 'react';
import { Typography } from '@material-ui/core';
import { css } from '@emotion/core';
import { SectionHeader } from './SectionHeader';
import { SectionDivider } from './SectionDivider';
import { toParagraphsArray } from 'src/util';
import { useText } from 'src/components/TextContext';
import UbicacionPhoto from '../../../../images/ubicacion-front.jpg';
import imgAdin from '../../../../images/logo/adin00.png';
import imgNipas from '../../../../images/logo/las-nipas00.png';

export const QuienesSomosSection = () => {
  const t = useText();
  const { content = '' } = t.quienesSomos;
  const contentArr = toParagraphsArray(content);

  return (
    <>
      <SectionHeader>{t?.quienesSomos?.title}</SectionHeader>
      <p>{t?.quienesSomos?.subtitle}</p>
      <SectionDivider />
      <div
        css={css`
          display: grid;
          margin: auto;
          font-size: 20px;
          padding: 0 1rem;
          @media (min-width: 70rem) {
            max-width: 70rem;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1.5cm;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          {contentArr.map((x, i) => (
            <Typography
              key={i}
              css={css`
                font-size: 18px;
                white-space: pre-wrap;
                margin: auto;
                text-align: justify;
                margin-bottom: 1rem;
              `}
            >
              {x}
            </Typography>
          ))}
          <div
            css={css`
              display: grid;
              grid-template-columns: 1fr 3rem 1fr;
              align-items: end;
              padding: 0 3rem;
            `}
          >
            <a
              href="https://www.adin.cl/"
              target="_blank"
              css={css`
                width: 100%;
              `}
            >
              <img
                src={imgAdin}
                css={css`
                  width: 100%;
                `}
              />
            </a>
            <div />
            <img
              src={imgNipas}
              css={css`
                width: 100%;
                margin-bottom: 6px;
              `}
            />
          </div>
        </div>
        <div>
          <img
            src={UbicacionPhoto}
            css={css`
              object-fit: scale-down;
              max-width: 100%;
            `}
          />
        </div>
      </div>
    </>
  );
};

export default QuienesSomosSection;
