import React from 'react';
import { Link } from 'gatsby';
import { Typography } from '@material-ui/core';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import {
  MailOutlined,
  CallOutlined,
  LocationOnOutlined,
} from '@material-ui/icons';
import { useText } from 'src/components/TextContext';

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-decoration: none;
`;

const IconContainer = styled.div`
  color: white;
  border-radius: 50%;
  border: solid white;
  display: flex;
  height: 2.8rem;
  width: 2.8rem;
  & > svg {
    margin: auto;
    font-size: 2rem;
  }
`;

const TextContainer = (props) => (
  <Typography
    variant="subtitle1"
    style={{ fontSize: '1.5rem !important' }}
    css={css`
      color: white;
      margin-left: 1rem;
      font-size: 2rem;
    `}
    {...props}
  />
);

export const ContactoSection = ({ ...props }) => {
  const t = useText();
  return (
    <div
      css={css`
        margin-top: 5rem;
        width: 100%;
        display: flex;
        background-color: rgba(0, 0, 0, 0.9);
        padding: 1rem;
      `}
      {...props}
    >
      <div
        css={css`
          display: grid;
          margin: auto;
        `}
      >
        <ContactItem>
          <IconContainer>
            <LocationOnOutlined />
          </IconContainer>
          <TextContainer>{t.contacto.direccion}</TextContainer>
        </ContactItem>
        <ContactItem>
          <IconContainer>
            <CallOutlined />
          </IconContainer>
          <TextContainer>{t.contacto.telefono}</TextContainer>
        </ContactItem>
        <ContactItem as={Link} to="/contacto">
          <IconContainer>
            <MailOutlined />
          </IconContainer>
          <TextContainer>{t.contacto.mail}</TextContainer>
        </ContactItem>
      </div>
    </div>
  );
};

export default ContactoSection;
